<template>
	<div>
		<!-- -->
		<s-crud
			title="Clientes de Maduración"
			:config="config"
			:filter="filter"
			add
			 @save="save($event)"
			edit 
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col cols="4" lg="4" md="6" sm="12">
							<s-text
								label="Nombre"
								v-model="props.item.CumNombre"
								ref="txtNombre"
							></s-text>
						</v-col>

						<v-col cols="4" lg="4" md="6" sm="12">
							<s-select-definition
								label="Cultivo"
								v-model="props.item.CumCultivo"
								:def="1173"
							></s-select-definition>
						</v-col>
					
                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-text
								label="Codigo SAP"
								v-model="props.item.CumCodigoSap"
								ref="txtCodigoSap"
							></s-text>
                        </v-col>

                        <v-col cols="4" lg="4" md="6" sm="12">
                            <s-select-definition
								label="Estado"
								v-model="props.item.CumState"
                                :def="1415"
                            ></s-select-definition>
                        </v-col>
                    </v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>



<script>
	import _sCustomersMaturationService from "@/services/FrozenProduction/FrzCustomersMaturationService.js"

	export default {
		data() {
			return {
				CtsProjectedDate: true,
				config: {
					model: {
						CumId: "ID"
					},
					service: _sCustomersMaturationService,
					headers: [
						{text: "ID", value: "CumId"},
						{text: "Nombre", value: "CumNombre"},
						{text: "Cultivo", value: "TypeCultiveName"},
						{text: "Codigo SAP", value: "CumCodigoSap"},
						{text: "Estado", value: "TypeProcessMadName"}

					]
				},
				filter: { CumNombre: 0},
			}
		},

		methods: {
	
 
			save(item) {

                if(item.CumNombre == "")
				{
					this.$fun.alert("Ingrese el nombre", "warning");
					this.$refs.txtNombre.focus();
					return;
				}

				item.save();
				
			},
		},
	}
</script>